import { AuthProvider } from 'context/auth';
import { ToastProvider } from 'context/toast';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import About from 'pages/About';
import Contact from 'pages/Contact';
import ForgotPwd from 'pages/ForgotPwd';
import Home from 'pages/Home';
import Login from 'pages/Login';
import Parties from 'pages/Parties';
import Party from 'pages/Party';
import PartyLogin from 'pages/PartyLogin';
import Places from 'pages/Places';
import Profile from 'pages/Profile';
import ResetPwd from 'pages/ResetPwd';
import Top from 'pages/Top';
import Users from 'pages/Users';
import Validated from 'pages/Validated';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ROUTES } from 'utils/routes';
import { Language, Token } from 'utils/storage';
import { Navbar } from './components/layout';
import en from './translations/en.json';
import fr from './translations/fr.json';
import './utils/global.css';

const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: new Language().get() || undefined,
    fallbackLng: 'en',
  });

export const App = () => {
  const token = new Token().get();

  return (
    <I18nextProvider i18n={i18n}>
      <ToastProvider>
        <AuthProvider authenticated={!!token} requestToken={token}>
          <div className="min-h-screen">
            <BrowserRouter>
              <Navbar />
              <Routes>
                <Route path={ROUTES.ABOUT_US} Component={About} />
                <Route path={ROUTES.CONTACT_US} Component={Contact} />
                <Route path={ROUTES.PARTY_VALIDATED} Component={Validated} />
                <Route path={ROUTES.PARTY_LOGIN} Component={PartyLogin} />
                <Route path="/forgot-password" Component={ForgotPwd} />
                <Route path="/reset-password" Component={ResetPwd} />
                <Route path={ROUTES.LOGIN} Component={Login} />
                <Route path={ROUTES.PARTY} Component={Party} />
                <Route path={ROUTES.TOP} Component={Top} />
                <Route path={ROUTES.PARTIES} Component={Parties} />
                <Route path={ROUTES.PLACES} Component={Places} />
                <Route path={ROUTES.USERS} Component={Users} />
                <Route path={ROUTES.ACCOUNT} Component={Profile} />
                <Route index path={ROUTES.HOME} Component={Home} />
              </Routes>
            </BrowserRouter>
          </div>
        </AuthProvider>
      </ToastProvider>
    </I18nextProvider>
  );
};
