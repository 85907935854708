import { UserAPI } from 'model/api';
import { User } from 'model/app';
import { AppSerializer } from './app';

export class UserSerializer extends AppSerializer<UserAPI, User> {
  // serialize(o: UserAPI, rq?: RequestInterface): Promise<User> {
  //   return super.serialize(o, rq);
  // }

  deserialize(o: User): Promise<UserAPI> {
    return super.deserialize(o).then(u => ({
      ...u,
      roles: typeof o.roles === 'string' ? [o.roles] : o.roles,
    }));
  }
}
