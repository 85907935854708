import { ScanAPI } from 'model/api';
import { Scan } from 'model/app';
import { AppSerializer } from './app';
import { RequestInterface } from 'actions/abstract';

export class ScanSerializer extends AppSerializer<ScanAPI, Scan> {
  serialize(o: ScanAPI, rq?: RequestInterface): Promise<Scan> {
    return super.serialize(o, rq);
  }
}
