import { AxiosResponse } from 'axios';
import { APIToken, UserLogin } from 'model';
import { UserAPI } from 'model/api';
import { User as UserModel } from 'model/app';
import { SerializerInterface, UserSerializer } from 'serializers';
import { Token } from 'utils/storage';
import { App } from './abstract';

export class User extends App<UserAPI, UserModel> {
  endpoint = '/users';
  protected serializer: SerializerInterface<UserAPI, UserModel> = new UserSerializer();

  forgotPassword(email: string): Promise<void> {
    return this.postRequest({ data: { email }, endpoint: '/forgot-password' }).then(() => {});
  }

  resetPassword(password: string, token: string): Promise<void> {
    return this.postRequest({ data: { password, token }, endpoint: '/reset-password' }).then(() => {});
  }
}

export class Dj extends User {
  endpoint = '/djs';
}

export class Manager extends User {
  endpoint = '/managers';
}

export class Roles extends User {
  endpoint = '';

  getRoles(): Promise<ReadonlyArray<string>> {
    return this.getRequest({ endpoint: '/roles' }).then(({ data }: { data: ReadonlyArray<string> }) => data);
  }
}

export class Auth extends User {
  endpoint = '/login_check';

  login(data: UserLogin): Promise<boolean> {
    const tokenInstance = new Token();
    tokenInstance.delete();
    return this.postRequest({ data: { ...data, username: data.email } }).then(
      ({ data: { token } }: AxiosResponse<APIToken>) => {
        tokenInstance.set(token);

        return true;
      },
    );
  }
}
