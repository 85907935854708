import { User as UserAction } from 'actions/user';
import { H2, H3, OutlinedButton, SimpleModal } from 'components/atomic';
import { Alert } from 'components/atomic/alert';
import { InputBase } from 'components/input';
import { usePushToast } from 'context/toast';
import { Party, Place, Scan, User } from 'model/app';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from 'utils/routes';

type SimpleTableProps = {
  Actions?: React.ElementType<{ item: ReadonlyArray<string | number> }>;
  data: ReadonlyArray<ReadonlyArray<string | number>>;
  emptyText: string;
  headers: ReadonlyArray<string>;
};

const SimpleTable = ({ Actions, data, emptyText, headers }: SimpleTableProps) => {
  const { t } = useTranslation();

  return (
    <div className="overflow-x-auto w-full shadow-md border border-neutral rounded-lg shadow-neutral">
      <table className="table">
        <thead className="text-neutral-content">
          <tr>
            {headers.map((h, idx) => (
              <th key={`${h}-${idx}`}>{h}</th>
            ))}
            {Actions && <th align="right">{t('common.table.headers.actions')}</th>}
          </tr>
        </thead>
        <tbody>
          {data.map((item, idx) => (
            <tr className="hover" key={`${item[0]}-${idx}`}>
              {item.slice(1, item.length).map((i, id) => (
                <td key={`${idx}-${id}`}>{i}</td>
              ))}
              {Actions && (
                <td className="flex gap-4 items-center justify-end">
                  <Actions item={item} />
                </td>
              )}
            </tr>
          ))}
          {!data.length && (
            <tr>
              <td colSpan={headers.length + (Actions ? 1 : 0)}>
                <Alert variant="ghost">{emptyText}</Alert>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

type handler = Dispatch<SetStateAction<boolean>>;

type TopTableRowProps = {
  data: Scan;
  toggleVisibility: (handler: handler) => void;
};

const TopTableRow: React.FC<TopTableRowProps> = ({ data, toggleVisibility }) => {
  const [isDisplayed, setDisplayed] = useState(true);
  const [isPlayed, setPlayed] = useState(false);
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    if (isDisplayed) {
      setHidden(false);
    } else {
      setTimeout(() => {
        setHidden(true);
      }, 500);
    }
  }, [isDisplayed]);

  return (
    <tr
      className={`hover:bg-neutral-content/20 transition-all duration-500 ${isPlayed ? 'bg-base-300' : ''} ${
        !isDisplayed ? `bg-error/50 opacity-0 ${hidden ? 'hidden' : ''}` : ''
      }`}
    >
      <td className="hidden md:table-cell">
        <img className="max-h-24 max-w-24 rounded-lg" src={data.image} />
      </td>
      <td className="whitespace-nowrap text-lg font-bold overflow-x-scroll">{data.title}</td>
      <td className="whitespace-nowrap text-lg font-bold overflow-x-scroll">{data.name}</td>
      <td align="center" className="text-lg font-bold hidden md:table-cell">
        {data.nb}
      </td>
      <td>
        <div className="flex gap-4 justify-center">
          <OutlinedButton
            variant="success"
            icon={isPlayed ? 'replay' : 'check'}
            onClick={() => {
              setPlayed(!isPlayed);
            }}
          />
          <OutlinedButton
            variant="danger"
            icon="remove"
            onClick={() => {
              setDisplayed(false);
              toggleVisibility(setDisplayed);
            }}
          />
        </div>
      </td>
    </tr>
  );
};

type TopTableProps = {
  data: ReadonlyArray<Scan>;
  loading: boolean;
};

export const TopTable: React.FC<TopTableProps> = ({ data, loading }) => {
  const { t } = useTranslation();
  const [handlers, setHandlers] = useState<ReadonlyArray<handler>>([]);

  return (
    <div className="flex flex-col gap-4">
      <div className="px-8 lg:px-0">
        <div className="overflow-x-auto w-full shadow-md border border-neutral rounded-lg shadow-neutral flex flex-col">
          <table className="table table-fixed md:table-auto">
            <thead className="text-neutral-content">
              <tr>
                <th className="hidden md:table-cell">{t('party.table.headers.cover')}</th>
                <th>{t('party.table.headers.song_name')}</th>
                <th>{t('party.table.headers.artist_name')}</th>
                <th className="hidden md:table-cell" align="center">
                  {t('party.table.headers.liked_by')}
                </th>
                <th align="center">{t('common.table.headers.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, id) => (
                <TopTableRow
                  data={item}
                  key={`${item.name}-${item.title}-${id}`}
                  toggleVisibility={handler => setHandlers(prev => [...prev, handler])}
                />
              ))}
              {!data.length && (
                <tr>
                  <td colSpan={5}>
                    <Alert variant="ghost">{t(loading ? 'party.table.loading' : 'party.table.no_records')}</Alert>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div
        className={`sticky bottom-0 w-full justify-between flex gap-4 py-4 px-8 rounded-lg bg-neutral/30 backdrop-blur-md ${
          !handlers.length ? 'hidden' : ''
        }`}
      >
        <span className="my-auto font-black">{t('party.table.action.remove.item', { count: handlers.length })}</span>
        <div className="flex-col md:flex-row flex gap-4">
          <OutlinedButton
            variant="neutral"
            text={t('party.table.action.cancel_previous')}
            onClick={() => {
              if (handlers.length) {
                handlers[handlers.length - 1](true);
                setHandlers(handlers.slice(0, handlers.length - 1));
              }
            }}
          />
          <OutlinedButton
            variant="danger"
            text={t('party.table.action.cancel_all')}
            onClick={() => {
              if (handlers.length) {
                handlers.forEach(handler => handler(true));
                setHandlers([]);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

type TableProps<T> = {
  data: ReadonlyArray<T>;
  filterable?: boolean;
  title: string;
};

type PartyTableProps = TableProps<Party>;

const QRCodeButton: React.FC<{ id: string }> = ({ id }) => {
  const [opened, setOpened] = useState<boolean>(false);
  return (
    <>
      <OutlinedButton className="w-fit" variant="neutral" icon="maximize" onClick={() => setOpened(true)} />
      {opened && (
        <SimpleModal isOpen={true} onClose={() => setOpened(false)}>
          <H3>QR Code</H3>
          <div className="flex flex-col items-center">
            <img
              className="w-3/4 my-3"
              id="qrCodeImg"
              src={`${process.env.REACT_APP_API_URL}/qrcode/${id}.png`}
              alt="QRCode"
            />
            <a href={`${ROUTES.PARTIES}/${id}/login`} target="_blank" rel="noopener noreferrer">
              {window.location.origin}
              {ROUTES.PARTIES}/{id}/login
            </a>
          </div>
        </SimpleModal>
      )}
    </>
  );
};

export const PartyTable: React.FC<PartyTableProps> = ({ data, filterable, title }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const filtered = useMemo(
    () =>
      data.filter(
        d =>
          d.place.address.toLowerCase().includes(search) ||
          d.place.name.toLowerCase().includes(search) ||
          d.date.toLocaleDateString().includes(search) ||
          d.dj.username.toLowerCase().includes(search),
      ),
    [data, search],
  );

  return (
    <>
      <H2 className="md:text-7xl pb-8">{title}</H2>
      {filterable && (
        <div className="flex mb-4">
          <InputBase
            name="search"
            placeholder={t('parties.table.filter.search')}
            onChange={({ target: { value } }) => setSearch(value.toLowerCase())}
          />
        </div>
      )}
      <SimpleTable
        data={filtered.map(item => [
          item.id,
          item.date.toLocaleDateString(),
          item.dj.username,
          item.place.name,
          item.place.address,
        ])}
        emptyText={t('parties.table.no_records')}
        headers={[
          t('parties.table.headers.date'),
          t('parties.table.headers.dj'),
          t('parties.table.headers.name'),
          t('parties.table.headers.location'),
        ]}
        Actions={({ item }) => {
          return (
            <>
              <QRCodeButton id={`${item[0]}`} />
              <Link to={ROUTES.PARTY.replace(':id', `${item[0]}`)}>
                <OutlinedButton variant="neutral" text={t('parties.table.actions.see_details')} />
              </Link>
            </>
          );
        }}
      />
    </>
  );
};

type PlaceTableProps = TableProps<Place>;
export const PlacesTable: React.FC<PlaceTableProps> = ({ data, filterable, title }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const filtered = useMemo(
    () => data.filter(d => d.address.toLowerCase().includes(search) || d.name.toLowerCase().includes(search)),
    [data, search],
  );

  return (
    <>
      <H2 className="md:text-7xl pb-8">{title}</H2>
      {filterable && (
        <div className="flex mb-4">
          <InputBase
            name="search"
            placeholder={t('places.table.filter.search')}
            onChange={({ target: { value } }) => setSearch(value.toLowerCase())}
          />
        </div>
      )}
      <SimpleTable
        data={filtered.map(item => [
          item.id,
          item.name,
          item.address,
          `${item.owner.firstname} ${item.owner.lastname}`,
        ])}
        emptyText={t('parties.table.no_records')}
        headers={[
          t('places.table.headers.name'),
          t('places.table.headers.location'),
          t('places.table.headers.location'),
        ]}
        // Actions={({ item }) => {
        //   return (
        //     <>
        //       <Link to={ROUTES.PARTY.replace(':id', `${item[0]}`)}>
        //         <OutlinedButton variant="neutral" text={t('parties.table.actions.see_details')} />
        //       </Link>
        //     </>
        //   );
        // }}
      />
    </>
  );
};

type UserTableProps = TableProps<User>;
export const UsersTable: React.FC<UserTableProps> = ({ data, filterable, title }) => {
  const pushToast = usePushToast();
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const filtered = useMemo(
    () =>
      data.filter(
        d =>
          d.email.toLowerCase().includes(search) ||
          d.firstname.toLowerCase().includes(search) ||
          d.lastname.toLowerCase().includes(search),
      ),
    [data, search],
  );

  return (
    <>
      <H2 className="md:text-7xl pb-8">{title}</H2>
      {filterable && (
        <div className="flex mb-4">
          <InputBase
            name="search"
            placeholder={t('users.table.filter.search')}
            onChange={({ target: { value } }) => setSearch(value.toLowerCase())}
          />
        </div>
      )}
      <SimpleTable
        data={filtered.map(item => [item.id, item.email, `${item.firstname} ${item.lastname}`, item.roles.join(' | ')])}
        emptyText={t('parties.table.no_records')}
        headers={[t('users.table.headers.email'), t('users.table.headers.fullname'), t('users.table.headers.roles')]}
        Actions={({ item }) => {
          return (
            <OutlinedButton
              variant="danger"
              icon="remove"
              onClick={() =>
                new UserAction()
                  .delete(`${item[0]}`)
                  .then(() => pushToast({ text: t('user.delete.success'), variant: 'success' }))
                  .catch(() => pushToast({ text: t('user.delete.fail'), variant: 'danger' }))
              }
            />
          );
        }}
      />
    </>
  );
};
