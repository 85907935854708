import useOnClickOutside from 'hooks/useClickOutside';
import React, { Dispatch, SetStateAction, useRef } from 'react';

type Dropdown = {
  action: React.ReactElement;
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const Dropdown: React.FC<React.PropsWithChildren<Dropdown>> = ({ action, children, isOpen, setOpen }) => {
  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    setOpen(false);
  });

  return (
    <div
      ref={ref}
      className={`dropdown dropdown-bottom relative py-4 lg:py-2 ${isOpen ? 'dropdown-open active' : ''}`}
      onClick={() => {
        setOpen(!isOpen);
      }}
    >
      {action}
      <ul
        tabIndex={0}
        className="menu dropdown-content z-[1] p-4 shadow bg-base-100 rounded-box min-w-min w-full mt-4 ml-0 gap-4"
      >
        {children}
      </ul>
    </div>
  );
};
