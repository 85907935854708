import React, { useEffect, useState } from 'react';
import { AppleButton as Apple, DeezerButton, H3, SpotifyButton } from 'components/atomic';
import { Party } from 'actions/party';
import { useNavigate, useParams } from 'react-router-dom';
import { usePushToast } from 'context/toast';
import { ROUTES } from 'utils/routes';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

const AppleButton: React.FC = () => {
  const pushToast = usePushToast();
  const { id = '' } = useParams();
  const push = useNavigate();
  // eslint-disable-next-line
  const [appleInstance, setAppleInstance] = useState<any>();
  const [appleScriptLoaded, setAppleScriptLoaded] = useState(false);
  const [appleToken, setAppleToken] = useState<string>();

  useEffect(() => {
    const scr = document.createElement('script');
    scr.src = 'https://js-cdn.music.apple.com/musickit/v1/musickit.js';
    document.body.appendChild(scr);
    scr.onload = () => {
      setAppleScriptLoaded(true);
    };
  }, [setAppleScriptLoaded]);

  useEffect(() => {
    new Party().getAppleToken(id).then(setAppleToken);
  }, [id, setAppleToken]);

  useEffect(() => {
    if (!appleScriptLoaded || !appleToken) {
      return;
    }

    // eslint-disable-next-line
    const { MusicKit }: any = window;
    if (MusicKit) {
      MusicKit.configure({
        developerToken: appleToken,
      });
      setAppleInstance(MusicKit);
    }
  }, [appleScriptLoaded, appleToken]);

  return (
    <Apple
      text={t('party_login.button.sign_in', { provider: 'Apple Music' })}
      onClick={() => {
        appleInstance
          .getInstance()
          .authorize()
          .then((token: string) => {
            new Party().login(id, 'apple', token).then(() => {
              pushToast({
                text: t('party_login.button.sign_in.success', { provider: 'Apple Music' }),
                variant: 'success',
              });
              push(ROUTES.PARTY_VALIDATED);
            });
          });
      }}
    />
  );
};

const OAuthButton: React.FC<{ id: string; provider: 'Spotify' | 'Deezer' }> = ({ id, provider }) => {
  const Button = provider === 'Deezer' ? DeezerButton : SpotifyButton;
  const party = new Party();

  return (
    <form action={`${party.getBaseUrl()}${party.getLoginEndpoint(id)}`} method="post">
      <input type="hidden" name="type" value={provider.toLowerCase()} />
      <Button className="w-full" text={t('party_login.button.sign_in', { provider })} />
    </form>
  );
};

const PartyLogin = () => {
  const { t } = useTranslation();
  const { id = '' } = useParams();

  return (
    <>
      <H3 className="pt-16 px-16">{t('party_login.title')}</H3>
      <div className="max-w-6xl mx-auto flex flex-col gap-8 py-16">
        <AppleButton />
        <OAuthButton id={id} provider="Spotify" />
        <OAuthButton id={id} provider="Deezer" />
      </div>
    </>
  );
};

export default PartyLogin;
