import { ThemeAPI } from 'model/api';
import { Theme as ThemeModel } from 'model/app';
import { SerializerInterface } from 'serializers';
import { ThemeSerializer } from 'serializers/theme';
import { App } from './abstract';

export class Theme extends App<ThemeAPI, ThemeModel> {
  endpoint = '/themes';
  protected serializer: SerializerInterface<ThemeAPI, ThemeModel> = new ThemeSerializer();
}
