import { OutlinedButton } from 'components/atomic';
import { Alert } from 'components/atomic/alert';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'utils/routes';

const Validated = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="flex grow">
      <div className="w-full px-8 lg:px-32 m-auto py-8 lg:py-24">
        <Alert variant="success" className="w-fit m-auto -mt-16" iconClassName="w-12 h-12">
          <div className="flex flex-col gap-8 md:gap-2">
            <h3 className="font-black text-2xl md:text-3xl lg:text-4xl">{t('validated.alert.1')}</h3>
            <h3 className="font-black text-2xl md:text-3xl lg:text-4xl">{t('validated.alert.2')}</h3>
          </div>
        </Alert>
        <div className="pt-8 mx-auto text-center">
          <OutlinedButton
            text={t('validated.button')}
            variant="secondary"
            onClick={() => {
              navigate(ROUTES.HOME);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Validated;
