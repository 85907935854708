import React from 'react';
import { AllowedVariant } from 'utils/common';
import { AllowedIcons, Icon } from './icon';

type buttonIcon = { icon: AllowedIcons; position: 'left' | 'right' };
type buttonText = { text?: string };
type additionalTypes = { variant?: AllowedVariant } & (buttonText | (buttonText & buttonIcon) | buttonIcon);
export type buttonType = additionalTypes &
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const Base: React.FC<React.PropsWithChildren<buttonType>> = ({ variant = 'info', type, className = '', ...props }) => (
  <button
    type={type}
    className={`btn border-2 font-extrabold ${computeClassFromProps({
      variant,
    })} ${className}`}
    {...props}
  >
    {(props as buttonIcon).icon && (props as buttonIcon).position !== 'right' ? (
      <Icon name={(props as buttonIcon).icon} className="text-inherit" size={24} />
    ) : null}
    {(props as buttonText).text ?? ''}
    {(props as buttonIcon).icon && (props as buttonIcon).position === 'right' ? (
      <Icon name={(props as buttonIcon).icon} className="text-inherit" size={24} />
    ) : null}
    {props.children}
  </button>
);

const ConnectWith: React.FC<buttonType> = props => (
  <Base {...props} className={`text-white hover:bg-transparent btn-lg ${props.className}`} />
);

export const AppleButton: React.FC<buttonType> = props => (
  <ConnectWith
    {...props}
    className={`border-rose-500 bg-rose-500 hover:border-rose-500 hover:text-rose-500 ${props.className}`}
  />
);

export const SpotifyButton: React.FC<buttonType> = props => (
  <ConnectWith
    {...props}
    className={`border-green-500 bg-green-500 hover:border-green-500 hover:text-green-500 ${props.className}`}
  />
);

export const DeezerButton: React.FC<buttonType> = props => (
  <ConnectWith
    {...props}
    className={`border-purple-700 bg-purple-700 hover:border-purple-700 hover:text-purple-700 ${props.className}`}
  />
);

export const OutlinedButton: React.FC<buttonType> = props => (
  <Base {...props} className={`btn-outline ${props.className}`} />
);

export const computeClassFromProps = ({ variant }: Omit<additionalTypes, 'text'>): string => {
  switch (variant) {
    case 'primary':
      return 'btn-primary';
    case 'secondary':
      return 'btn-secondary';
    case 'info':
      return 'btn-info';
    case 'danger':
      return 'btn-error';
    case 'success':
      return 'btn-success';
    case 'warning':
      return 'btn-warning';
    case 'ghost':
      return 'btn-ghost';
    case 'neutral':
      return 'btn-neutral';
    case 'default':
      return 'btn-default';
  }

  return '';
};

export const BaseButton: React.FC<buttonType> = props => {
  let textColor = '';
  switch (props.variant ?? 'info') {
    case 'primary':
      textColor = 'hover:text-primary';
      break;
    case 'secondary':
      textColor = 'hover:text-secondary';
      break;
    case 'info':
      textColor = 'hover:text-info';
      break;
    case 'danger':
      textColor = 'hover:text-error';
      break;
    case 'success':
      textColor = 'hover:text-success';
      break;
    case 'warning':
      textColor = 'hover:text-warning';
      break;
    case 'ghost':
      textColor = 'hover:text-ghost';
      break;
  }

  return <Base {...props} className={`${props.className} text-base-100 hover:bg-transparent ${textColor}`} />;
};
