import React from 'react';

export type textareaType = {
  label?: string;
  id?: string;
  optional?: boolean;
  textareaClassName?: string;
} & React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;

export const TextArea: React.FC<textareaType> = ({ className = '', label, optional, textareaClassName, ...area }) => {
  return (
    <div className={`form-control gap-y-1 ${className}`}>
      {label && (
        <label htmlFor={area.id} className="self-start">
          {label}
          {optional ? ' (optional)' : ' *'}
        </label>
      )}
      <textarea
        className={`textarea textarea-bordered border-neutral-content ${textareaClassName}`}
        {...area}
      ></textarea>
    </div>
  );
};
