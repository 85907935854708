import { Email } from 'actions/email';
import { SimpleCard } from 'components/card';
import { Form } from 'components/form';
import { usePushToast } from 'context/toast';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type mode = 'bug_report' | 'party' | 'organizer';

const Contact = () => {
  const { t } = useTranslation();
  const pushToast = usePushToast();
  const [mode, setMode] = useState<mode>();

  return (
    <div className="w-full lg:max-w-4xl lg:max-w-6xl px-8 lg:px-32 mx-auto flex flex-col lg:flex-row py-8 lg:py-24">
      <SimpleCard>
        <Form
          title={t('contact.form.title')}
          inputs={[
            {
              name: 'status',
              label: t('contact.form.status.label'),
              type: 'select',
              required: true,
              placeholder: t('contact.form.status.placeholder'),
              onChange: ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => setMode(value as mode),
              options: [
                {
                  name: t('contact.form.status.options.company'),
                  value: 'company',
                },
                {
                  name: t('contact.form.status.options.person'),
                  value: 'person',
                },
              ],
            },
            {
              name: 'type',
              label: t('contact.form.reason.label'),
              type: 'select',
              required: true,
              placeholder: t('contact.form.reason.placeholder'),
              onChange: ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => setMode(value as mode),
              options: [
                {
                  name: t('contact.form.reason.options.party.name'),
                  value: 'party',
                },
                {
                  name: t('contact.form.reason.options.organize.name'),
                  value: 'organizer',
                },
                {
                  name: t('contact.form.reason.options.bug_report.name'),
                  value: 'bug_report',
                },
              ],
            },
            ...(mode === 'organizer' || mode === 'party'
              ? [
                  {
                    placeholder: t('contact.form.first_name.placeholder'),
                    name: 'first_name',
                    label: t('contact.form.first_name.label'),
                  },
                  {
                    placeholder: t('contact.form.last_name.placeholder'),
                    name: 'last_name',
                    label: t('contact.form.last_name.label'),
                  },
                ]
              : []),
            {
              placeholder: t('contact.form.email.placeholder'),
              name: 'email',
              label: t('contact.form.email.label'),
              type: 'email',
            },
            ...(mode === 'organizer'
              ? [
                  {
                    placeholder: t('contact.form.phone.placeholder'),
                    name: 'phone',
                    label: t('contact.form.phone.label'),
                    type: 'phone',
                  },
                ]
              : []),
            {
              placeholder: t('contact.form.description.placeholder'),
              name: 'message',
              label: t('contact.form.description.label'),
              type: 'textarea',
            },
          ]}
          buttonProps={{
            text: t('contact.form.button.submit'),
            variant: 'success',
            disabled: !mode,
          }}
          handleSubmit={async values => {
            return new Email()
              .contact(values)
              .then(() => {
                pushToast({ text: t('contact.form.toast.success'), variant: 'success' });
              })
              .catch(err => {
                pushToast({ text: t('contact.form.toast.error'), variant: 'danger' });
                throw err;
              });
          }}
        />
      </SimpleCard>
    </div>
  );
};

export default Contact;
