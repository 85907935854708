import React from 'react';
import { InputBase, inputType } from './input';
import { MultiSelectProps, Select, SelectProps } from './select';
import { TextArea, textareaType } from './textarea';

export type Selectable = { type: 'select' } & (
  | MultiSelectProps
  | (SelectProps & React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>)
);
type TextAreable = { type: 'textarea' } & textareaType;
export type InputGuesserProps = Selectable | TextAreable | inputType;

export const InputGuesser: React.FC<InputGuesserProps> = ({ type, ...props }) => {
  switch (type) {
    case 'select':
      return <Select {...(props as Selectable)} />;
    case 'textarea':
      return <TextArea {...(props as TextAreable)} />;
    default:
      return <InputBase type={type} {...(props as inputType)} />;
  }
};
