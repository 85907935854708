import useOnClickOutside from 'hooks/useClickOutside';
import React, { useEffect, useRef, useState } from 'react';

type SimpleModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const SimpleModal: React.FC<React.PropsWithChildren<SimpleModalProps>> = ({ children, isOpen, onClose }) => {
  const ref = useRef(null);
  const [mounted, setMounted] = useState(false);

  useOnClickOutside(ref, () => {
    if (mounted) {
      onClose();
    }
  });

  useEffect(() => {
    setMounted(true);
  }, [setMounted]);

  return (
    <dialog className={`modal ${isOpen ? 'modal-open' : ''}`}>
      <div className="modal-box" ref={ref}>
        {children}
      </div>
      <form method="dialog" className="modal-backdrop">
        <button>close</button>
      </form>
    </dialog>
  );
};
