import React from 'react';
import { AllowedVariant } from 'utils/common';

type UserCardProps = {
  firstname: string;
  image: string;
  job: string;
  variant?: AllowedVariant;
};

type PhilosophyCardProps = {
  description: string;
  icon: string;
  title: string;
};

const BaseCard: React.FC<React.PropsWithChildren<{ className?: string }>> = ({ children, className = '' }) => (
  <div className={`card bg-neutral-focus/30 ${className}`}>{children}</div>
);

export const AboutCard: React.FC<React.PropsWithChildren> = ({ children }) => (
  <BaseCard className="shadow-lg rounded-2xl h-full min-w-full p-8">{children}</BaseCard>
);

export const SimpleCard: React.FC<React.PropsWithChildren> = ({ children }) => (
  <BaseCard className="shadow-lg rounded-2xl h-full min-w-full flex items-center justify-center py-16 px-8">
    {children}
  </BaseCard>
);

export const PhilosophyCard: React.FC<PhilosophyCardProps> = ({ description, icon, title }) => (
  <BaseCard className="card-body items-center text-center text-primary-content gap-y-8">
    <img src={`/images/${icon}.svg`} alt={icon} className="-mt-24 px-8 lg:-mt-40" />
    <h2 className="card-title font-black text-5xl">{title}</h2>
    <p className="text-xl">{description}</p>
  </BaseCard>
);

const mapVariantToClassname = (variant?: AllowedVariant): string => {
  switch (variant) {
    case 'primary':
      return 'text-primary';
    case 'secondary':
      return 'text-secondary';
  }

  return '';
};

export const UserCard: React.FC<UserCardProps> = ({ firstname, image, job, variant }) => (
  <BaseCard className="bg-transparent w-full">
    <img src={`/images//team/${image}.png`} alt={firstname} className="rounded-xl h-72 object-cover" />
    <div className="card-body py-2 items-center text-center text-primary-content text-3xl uppercase font-bold">
      <h2 className={mapVariantToClassname(variant)}>{firstname}</h2>
      <p>{job}</p>
    </div>
  </BaseCard>
);
