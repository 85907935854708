import { PartyAPI } from 'model/api';
import { Party as PartyModel } from 'model/app';
import { SerializerInterface } from 'serializers';
import { PartySerializer } from 'serializers/party';
import { App } from './abstract';

export class Party extends App<PartyAPI, PartyModel> {
  endpoint = '/parties';
  protected serializer: SerializerInterface<PartyAPI, PartyModel> = new PartySerializer();

  getLoginEndpoint(id: string) {
    return `${this.endpoint}/${id}/login`;
  }

  login(id: string, type: 'apple' | 'spotify' | 'deezer', token: string) {
    return this.postRequest({
      data: { token, type },
      endpoint: `/${id}/login`,
    });
  }

  getAppleToken(id: string): Promise<string> {
    return this.getRequest({
      endpoint: `/${id}/apple-token`,
    }).then(({ data: { token } }) => token);
  }

  getThemes(): Promise<ReadonlyArray<string>> {
    return this.getRequest().then(({ data }) => data);
  }
}
