import { Place as PlaceAction } from 'actions/place';
import { Manager } from 'actions/user';
import { BaseButton, SimpleModal } from 'components/atomic';
import { Form } from 'components/form';
import { option } from 'components/input';
import { PlacesTable } from 'components/table';
import { useRedirectIfNotLogged } from 'context/auth';
import { usePushToast } from 'context/toast';
import { Place } from 'model/app';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type FormOptions = { owners: ReadonlyArray<option> };

const CreatePlace = () => {
  const { t } = useTranslation();
  const pushToast = usePushToast();
  const [isOpen, setOpen] = useState(false);
  const [formOptions, setFormOptions] = useState<FormOptions>({ owners: [] });

  useEffect(() => {
    Promise.all([new Manager().getMany()]).then(([{ items: managers }]) => {
      setFormOptions({
        owners: managers.map(({ firstname, lastname, '@id': id }) => ({
          name: `${firstname} ${lastname}`,
          value: `${id}`,
        })),
      });
    });
  }, [setFormOptions]);

  return (
    <>
      <BaseButton
        className="w-fit text-white"
        variant="primary"
        icon="plus-square"
        text={t('places.create.title')}
        onClick={() => {
          setOpen(true);
        }}
      />
      {isOpen && (
        <SimpleModal isOpen onClose={() => setOpen(false)}>
          <Form
            title={t('places.create.title')}
            titleSize="h3"
            inputs={[
              {
                name: 'name',
                label: t('place.create.form.name.label'),
                placeholder: t('place.create.form.name.placeholder'),
              },
              {
                name: 'address',
                label: t('place.create.form.location.label'),
                placeholder: t('place.create.form.location.placeholder'),
              },
              {
                placeholder: t('place.create.form.owner.placeholder'),
                defaultValue: '',
                name: 'manager',
                label: t('place.create.form.owner.label'),
                type: 'select',
                required: true,
                options: formOptions.owners,
              },
            ]}
            buttonProps={{
              text: t('common.form.create'),
              variant: 'success',
            }}
            handleSubmit={async values => {
              return new PlaceAction()
                .create(values)
                .then(() => {
                  setOpen(false);
                  pushToast({ text: t('place.create.success'), variant: 'success' });
                })
                .catch(err => {
                  pushToast({ text: t('place.create.fail'), variant: 'danger' });
                  throw err;
                });
            }}
          />
        </SimpleModal>
      )}
    </>
  );
};

const Places = () => {
  useRedirectIfNotLogged();
  const { t } = useTranslation();
  const [places, setPlaces] = useState<ReadonlyArray<Place>>();

  useEffect(() => {
    new PlaceAction().getMany().then(({ items }) => {
      setPlaces(items);
    });
  }, [setPlaces]);

  return (
    <div className="w-full lg:max-w-4xl lg:max-w-6xl mx-auto flex flex-col gap-16 py-8 lg:py-24 px-4 lg:px-16">
      <div>
        <CreatePlace />
        <PlacesTable data={places ?? []} title={t('places.table.title')} />
      </div>
    </div>
  );
};

export default Places;
