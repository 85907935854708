import { Dj, Manager, Roles, User as UserAction } from 'actions/user';
import { BaseButton, SimpleModal } from 'components/atomic';
import { Form } from 'components/form';
import { option } from 'components/input';
import { UsersTable } from 'components/table';
import { useRedirectIfNotLogged } from 'context/auth';
import { usePushToast } from 'context/toast';
import { User } from 'model/app';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type FormOptions = { owners: ReadonlyArray<option>; roles: ReadonlyArray<option> };

const CreateUser = () => {
  const { t } = useTranslation();
  const pushToast = usePushToast();
  const [isOpen, setOpen] = useState(false);
  const [formOptions, setFormOptions] = useState<FormOptions>({ owners: [], roles: [] });
  const [selectedRoles, setSelectedRoles] = useState<ReadonlyArray<option>>([]);
  const [type, setType] = useState<string>();

  useEffect(() => {
    Promise.all([new Manager().getMany(), new Roles().getRoles()]).then(([{ items: managers }, roles]) => {
      setFormOptions({
        owners: managers.map(({ firstname, lastname, '@id': id }) => ({
          name: `${firstname} ${lastname}`,
          value: `${id}`,
        })),
        roles: roles.map(role => ({ name: role, value: role })),
      });
    });
  }, [setFormOptions]);

  return (
    <>
      <BaseButton
        className="w-fit text-white"
        variant="primary"
        icon="plus-square"
        text={t('users.create.title')}
        onClick={() => {
          setOpen(true);
        }}
      />
      {isOpen && (
        <SimpleModal isOpen onClose={() => setOpen(false)}>
          <Form
            title={t('users.create.title')}
            titleSize="h3"
            inputs={[
              {
                name: 'type',
                label: t('user.create.form.type.label'),
                placeholder: t('user.create.form.email.placeholder'),
                type: 'select',
                options: ['Admin', 'Manager', 'DJ'].map(v => ({ name: v, value: v.toLowerCase() })),
                onChange: ({ target: { value } }: React.ChangeEvent<HTMLSelectElement>) => setType(value),
              },
              {
                name: 'email',
                label: t('user.create.form.email.label'),
                placeholder: t('user.create.form.email.placeholder'),
              },
              {
                name: 'password',
                label: t('user.create.form.password.label'),
                placeholder: t('user.create.form.password.placeholder'),
              },
              {
                name: 'firstname',
                label: t('user.create.form.firstname.label'),
                placeholder: t('user.create.form.firstname.placeholder'),
              },
              {
                name: 'lastname',
                label: t('user.create.form.lastname.label'),
                placeholder: t('user.create.form.lastname.placeholder'),
              },
              ...(type === 'dj'
                ? [
                    {
                      name: 'username',
                      label: t('user.create.form.username.label'),
                      placeholder: t('user.create.form.username.placeholder'),
                    },
                  ]
                : []),
              {
                placeholder: t('user.create.form.roles.placeholder'),
                name: 'roles',
                isMultiple: true,
                label: 'Roles',
                type: 'select',
                options: formOptions.roles,
                selectedOptions: selectedRoles,
                handleChange: setSelectedRoles,
                required: true,
              },
            ]}
            buttonProps={{
              text: t('common.form.create'),
              variant: 'success',
            }}
            handleSubmit={async values => {
              let action = new UserAction();
              switch (values.type) {
                case 'manager':
                  action = new Manager();
                  break;
                case 'dj':
                  action = new Dj();
                  break;
              }

              return action
                .create(values)
                .then(() => {
                  setOpen(false);
                  pushToast({ text: t('user.create.success'), variant: 'success' });
                })
                .catch(err => {
                  pushToast({ text: t('user.create.fail'), variant: 'danger' });
                  throw err;
                });
            }}
          />
        </SimpleModal>
      )}
    </>
  );
};

const Users = () => {
  useRedirectIfNotLogged();
  const { t } = useTranslation();
  const [users, setUsers] = useState<ReadonlyArray<User>>();

  useEffect(() => {
    new UserAction().getMany().then(({ items }) => {
      setUsers(items);
    });
  }, [setUsers]);

  return (
    <div className="w-full lg:max-w-4xl lg:max-w-6xl mx-auto flex flex-col gap-16 py-8 lg:py-24 px-4 lg:px-16">
      <div>
        <CreateUser />
        <UsersTable data={users ?? []} title={t('users.table.title')} />
      </div>
    </div>
  );
};

export default Users;
