import { APIList } from 'model';
import { ScanAPI } from 'model/api';
import { Scan as ScanModel } from 'model/app';
import { SerializerInterface } from 'serializers';
import { ScanSerializer } from 'serializers/scan';
import { App } from './abstract';

export class Scan extends App<ScanAPI, ScanModel> {
  endpoint = '';
  protected serializer: SerializerInterface<ScanAPI, ScanModel> = new ScanSerializer();

  getTop({ id }: { id: string }): Promise<APIList<ScanModel>> {
    return this.getRequest({ endpoint: `/parties/${id}/top` }).then(({ data: v }) => {
      return this.serializer.serializeMany(v['hydra:member']).then(items => ({
        items,
        total: v['hydra:totalItems'],
      }));
    });
  }
}
