import React from 'react';
import {
  Check,
  ChevronDown,
  ChevronUp,
  Icon as FeatherIcon,
  Headphones,
  Heart,
  Instagram,
  Maximize,
  Repeat,
  Share,
  Trash2,
  User,
  UserPlus,
  Users,
  X,
  PlusSquare,
} from 'react-feather';

export type AllowedIcons =
  | 'check'
  | 'chevron-down'
  | 'chevron-up'
  | 'headphones'
  | 'heart'
  | 'instagram'
  | 'remove'
  | 'share'
  | 'user'
  | 'x'
  | 'maximize'
  | 'user-plus'
  | 'users'
  | 'plus-square'
  | 'replay';

function mapIcon(name: AllowedIcons): FeatherIcon | undefined {
  switch (name) {
    case 'check':
      return Check;
    case 'chevron-down':
      return ChevronDown;
    case 'chevron-up':
      return ChevronUp;
    case 'headphones':
      return Headphones;
    case 'heart':
      return Heart;
    case 'instagram':
      return Instagram;
    case 'maximize':
      return Maximize;
    case 'remove':
      return Trash2;
    case 'replay':
      return Repeat;
    case 'share':
      return Share;
    case 'user':
      return User;
    case 'user-plus':
      return UserPlus;
    case 'users':
      return Users;
    case 'x':
      return X;
    case 'plus-square':
      return PlusSquare;
  }
}

type iconProps = {
  name: AllowedIcons;
  size?: number;
  className?: string;
  onClick?: () => void;
};

export const Icon: React.FC<iconProps> = ({ name, size, ...props }) => {
  const InternalIcon = mapIcon(name);
  if (!InternalIcon) {
    return null;
  }
  return <InternalIcon size={size ? size : 48} {...props} />;
};
