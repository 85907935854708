import { Auth } from 'actions/user';
import { SimpleCard } from 'components/card';
import { Form } from 'components/form';
import { useDispatchAuth, useRedirectIfLogged } from 'context/auth';
import { usePushToast } from 'context/toast';
import { UserLogin } from 'model';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Login = () => {
  useRedirectIfLogged();
  const { t } = useTranslation();
  const setConnected = useDispatchAuth();
  const pushToast = usePushToast();

  return (
    <div className="w-full lg:max-w-4xl lg:max-w-6xl px-8 lg:px-32 mx-auto flex flex-col lg:flex-row py-8 lg:py-24">
      <SimpleCard>
        <Form
          title="Sign in"
          inputs={[
            {
              placeholder: t('sign_in.form.email.placeholder'),
              name: 'email',
              label: t('sign_in.form.email.label'),
              type: 'email',
            },
            {
              placeholder: t('common.form.password.placeholder'),
              name: 'password',
              label: t('sign_in.form.password.label'),
              type: 'password',
            },
          ]}
          buttonProps={{
            text: t('sign_in.form.button.submit'),
            variant: 'success',
          }}
          handleSubmit={async values => {
            return new Auth()
              .login(values as UserLogin)
              .then(() => {
                setConnected(true);
                pushToast({ text: t('sign_in.form.toast.success'), variant: 'success' });
              })
              .catch(err => {
                pushToast({ text: t('sign_in.form.toast.error'), variant: 'danger' });
                throw err;
              });
          }}
        />
        <Link to="/forgot-password" className="block mt-5 text-white hover:text-secondary">
          {t('sign_in.link.forgot_password')}
        </Link>
      </SimpleCard>
    </div>
  );
};

export default Login;
