import React from 'react';

export const H2: React.FC<React.PropsWithChildren<{ className?: string }>> = ({ children, className = '' }) => (
  <h2 className={`text-center text-white text-4xl md:text-7xl mx-auto justify-center ${className || ''}`}>
    {children}
  </h2>
);

export const H3: React.FC<React.PropsWithChildren<{ className?: string }>> = ({ children, className = '' }) => (
  <h3 className={`text-center text-white text-3xl md:text-4xl lg:text-5xl mx-auto justify-center ${className || ''}`}>
    {children}
  </h3>
);
