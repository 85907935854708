import { EmailAPI } from 'model/api';
import { Email as EmailModel } from 'model/app';
import { App } from './abstract';

export class Email extends App<EmailAPI, EmailModel> {
  endpoint = '/contact';

  contact(data: EmailAPI) {
    return this.postRequest({ data });
  }
}
