import { Party as PartyAction } from 'actions/party';
import { H3 } from 'components/atomic';
import { TopTable } from 'components/table';
import { useRedirectIfNotLogged } from 'context/auth';
import { Party as PartyModel, Scan as ScanModel } from 'model/app';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const Party = () => {
  useRedirectIfNotLogged();
  const { t } = useTranslation();
  const { id } = useParams();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [party, setParty] = useState<PartyModel>();
  const [scans, setScans] = useState<ReadonlyArray<ScanModel>>([]);

  useEffect(() => {
    Promise.all([new PartyAction().getOne({ id: id ?? '' }) /*, new Scan().getTop({ id: id ?? '' })*/])
      .then(([partyValue /*, { items }*/]) => {
        setParty(partyValue);
        // setScans(items);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return (
    <div className="w-full lg:max-w-4xl lg:max-w-6xl mx-auto flex flex-col gap-16 py-8 lg:py-24">
      <H3>
        {t('party.title_address_theme', {
          address: party?.place.address,
          interpolation: { escapeValue: false },
        })}
        <br />
        <span className="text-transparent bg-gradient-to-r from-primary to-secondary bg-clip-text">
          {party?.themes.map(({ name }) => name).join(' • ')}
        </span>
      </H3>
      <TopTable data={scans} loading={isLoading} />
    </div>
  );
};

export default Party;
