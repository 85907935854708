interface Storage<T> {
  get(key: string): T;
  set(key: string, value: T): void;
  delete(key: string): void;
}

export class LocalStorage implements Storage<string> {
  get(key: string) {
    return localStorage.getItem(key) ?? '';
  }

  set(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  delete(key: string) {
    localStorage.removeItem(key);
  }
}

abstract class Local {
  key = '';
  storage: Storage<string>;

  constructor() {
    this.storage = new LocalStorage();
  }

  get() {
    return this.storage.get(this.key);
  }

  set(value: string) {
    this.storage.set(this.key, value);
  }

  delete() {
    this.storage.delete(this.key);
  }
}

export class Token extends Local {
  key = 'token';
}

export class Language extends Local {
  key = 'language';
}
