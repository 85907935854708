import React from 'react';
import { AllowedVariant, ClassName } from 'utils/common';

const mapVariantToAlert = (variant: AllowedVariant) => {
  switch (variant) {
    case 'default':
      return 'alert-default';
    case 'neutral':
      return 'alert-neutral';
    case 'primary':
      return 'alert-primary';
    case 'secondary':
      return 'alert-secondary';
    case 'success':
      return 'alert-success';
    case 'danger':
      return 'alert-error';
    case 'ghost':
      return 'alert-ghost';
    case 'info':
      return 'alert-info';
    case 'warning':
      return 'alert-warning';
  }
};

const AlertIcon: React.FC<{ variant?: AllowedVariant }> = ({ variant }) => {
  switch (variant) {
    case 'ghost':
    case 'info':
      return (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      );
    case 'success':
      return (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      );
    case 'warning':
      return (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
        />
      );
    case 'danger':
      return (
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      );
  }
};

export const Alert: React.FC<
  React.PropsWithChildren<ClassName & { variant?: AllowedVariant; iconClassName?: string }>
> = ({ children, className, iconClassName = 'w-6 h-6', variant = 'info' }) => {
  return (
    <div className={`alert ${mapVariantToAlert(variant)} ${className ?? ''}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        className={`stroke-current shrink-0 ${iconClassName}`}
      >
        <AlertIcon variant={variant} />
      </svg>
      {children}
    </div>
  );
};
