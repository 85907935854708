import React, { PropsWithChildren, useState } from 'react';
import { InputGuesser, InputGuesserProps } from 'components/input';
import { OutlinedButton, buttonType } from 'components/atomic/button';
import { FormEventHandler } from 'react';
import { H2, H3 } from 'components/atomic';
import { Alert } from 'components/atomic/alert';

type redirectionType = {
  text: string;
  highlightText: string;
  redirectionLink: string;
};

type additionalTypes = {
  titleSize?: 'h2' | 'h3';
  inputs: ReadonlyArray<InputGuesserProps>;
  buttonProps?: buttonType;
  redirectionInformation?: redirectionType;
  validate?: (values: Record<string, string>) => ReadonlyArray<string>;
  // eslint-disable-next-line
  handleSubmit?: (values: any) => Promise<void>;
};

type formType = additionalTypes & React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>;
export type FormEventWithValues<T> = FormEventHandler<T> | { target: { values: T } };

export const Form: React.FC<PropsWithChildren<formType>> = ({
  children,
  className,
  inputs,
  buttonProps,
  handleSubmit,
  titleSize,
  title,
  validate,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<ReadonlyArray<string>>();

  const Title = titleSize === 'h2' ? H2 : H3;

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        setIsLoading(true);
        const form = event.target as HTMLFormElement;
        const values = Array.from(form.elements).reduce((data: Record<string, string>, element) => {
          if (
            (element instanceof HTMLInputElement ||
              element instanceof HTMLTextAreaElement ||
              element instanceof HTMLSelectElement) &&
            element.name
          ) {
            data[element.name] = element.value;
          }
          return data;
        }, {});

        const err = validate?.(values);
        setErrors(err);
        if (err?.length) {
          setIsLoading(false);
          return;
        }

        handleSubmit?.(values)
          .then(res => {
            form.reset();
            return res;
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.log(err);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }}
      {...props}
      className={`flex w-full justify-center gap-y-8 ${className ?? 'flex-col'}`}
    >
      {title && <Title>{title}</Title>}
      {errors?.map((e, idx) => (
        <Alert variant="danger" key={`${idx}-e`}>
          {e}
        </Alert>
      ))}
      {inputs ? inputs.map((inputProps, idx) => <InputGuesser key={idx} {...inputProps} />) : ''}
      {children}
      <div className="m-auto grid gap-4">
        {buttonProps && (
          <OutlinedButton
            {...{
              ...buttonProps,
              ...(isLoading
                ? {
                    icon: undefined,
                    text: undefined,
                  }
                : {}),
            }}
            disabled={isLoading || buttonProps.disabled}
            className={`m-auto transition-all transition-duration-300 ${buttonProps.className ?? ''}`}
          >
            {isLoading ? <span className="btn-disabled loading loading-dots loading-lg" /> : undefined}
          </OutlinedButton>
        )}
      </div>
    </form>
  );
};
