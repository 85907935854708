import { RequestInterface } from 'actions/abstract';
import { User } from 'actions/user';
import { PlaceAPI } from 'model/api';
import { Place, User as UserModel } from 'model/app';
import { AppSerializer } from './app';

export class PlaceSerializer extends AppSerializer<PlaceAPI, Place> {
  serialize(o: PlaceAPI, rq?: RequestInterface): Promise<Place> {
    return super.serialize(o, rq).then(place => {
      return Promise.all([new User().getOne({ id: `${o.manager.split('/').pop()}` })]).then(([owner]) => ({
        ...place,
        owner: owner as UserModel,
      }));
    });
  }

  serializeMany(o: ReadonlyArray<PlaceAPI>, rq?: RequestInterface): Promise<ReadonlyArray<Place>> {
    const userIds = [...new Set(o.map(place => `${place.manager.split('/').pop()}`))];
    return Promise.all(userIds.map(id => new User().getOne({ id })))
      .then(users =>
        users.reduce((acc: Record<string, UserModel>, current) => {
          acc[current.id] = current;
          return acc;
        }, {}),
      )
      .then(users =>
        o.map(place => ({
          ...place,
          owner: users[place.manager.split('/').pop() ?? ''],
        })),
      );
  }
}
