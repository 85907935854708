import { User } from 'actions/user';
import { SimpleCard } from 'components/card';
import { Form } from 'components/form';
import { useSearchParams } from 'react-router-dom';
import { usePushToast } from 'context/toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'utils/routes';

const ResetPwd = () => {
  const [params] = useSearchParams();
  const pushToast = usePushToast();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="w-full lg:max-w-4xl lg:max-w-6xl px-8 lg:px-32 mx-auto flex flex-col lg:flex-row py-8 lg:py-24">
      <SimpleCard>
        <Form
          title={t('reset_password.form.title')}
          titleSize="h3"
          inputs={[
            {
              placeholder: t('reset_password.form.password.placeholder'),
              name: 'password',
              label: t('reset_password.form.password.label'),
              type: 'password',
              required: true,
            },
            {
              placeholder: t('reset_password.form.passwordConfirmation.placeholder'),
              name: 'passwordConfirmation',
              label: t('reset_password.form.passwordConfirmation.label'),
              type: 'password',
              required: true,
            },
          ]}
          buttonProps={{
            text: t('reset_password.form.button.submit'),
            variant: 'success',
          }}
          validate={values => {
            if (values.password !== values.passwordConfirmation) {
              return [t('reset_password.form.validation.password.error')];
            }
            return [];
          }}
          handleSubmit={values =>
            new User()
              .resetPassword(values.password, params.get('token')!)
              .then(() => {
                pushToast({ text: t('reset_password.form.toast.success'), variant: 'success' });
                navigate(ROUTES.LOGIN);
              })
              .catch(err => {
                pushToast({ text: t('reset_password.form.toast.error'), variant: 'danger' });
                throw err;
              })
          }
        />
      </SimpleCard>
    </div>
  );
};

export default ResetPwd;
