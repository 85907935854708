import { RequestInterface } from 'actions/abstract';
import { APISingleResult } from 'model';
import { SerializerInterface } from './interface';

export class AppSerializer<T extends APISingleResult, U extends APISingleResult> implements SerializerInterface<T, U> {
  domain: string = '';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  serialize(o: APISingleResult<T>, rq?: RequestInterface): Promise<U> {
    return Promise.resolve({
      ...(o as unknown as U),
      id: o['@id']?.split('/').pop(),
    });
  }
  serializeMany(o: ReadonlyArray<T>, rq?: RequestInterface): Promise<ReadonlyArray<U>> {
    return Promise.all(o.map(item => this.serialize(item, rq)));
  }

  deserialize(o: U): Promise<T> {
    return Promise.resolve(o as unknown as T);
  }
}
