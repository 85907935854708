import { Party } from 'actions/party';
import { Place } from 'actions/place';
import { Theme } from 'actions/theme';
import { Dj, User } from 'actions/user';
import { BaseButton, SimpleModal } from 'components/atomic';
import { Form } from 'components/form';
import { Selectable, option } from 'components/input';
import { PartyTable } from 'components/table';
import { useRedirectIfNotLogged } from 'context/auth';
import { usePushToast } from 'context/toast';
import { Party as PartyModel, User as UserModel } from 'model/app';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type reducerType = {
  past: ReadonlyArray<PartyModel>;
  incoming: ReadonlyArray<PartyModel>;
  today: ReadonlyArray<PartyModel>;
};

const sortDate = (a: PartyModel, b: PartyModel) => b.date.getTime() - a.date.getTime();

type TabbedPartiesProps = {
  parties: { incoming: ReadonlyArray<PartyModel>; past: ReadonlyArray<PartyModel> };
};
const TabbedParties: React.FC<TabbedPartiesProps> = ({ parties }) => {
  useRedirectIfNotLogged();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<'incoming' | 'past'>('incoming');
  return (
    <div>
      <div className="pb-8 flex">
        <div className="tabs tab-lg tabs-boxed mx-auto h-fit flex">
          <span
            onClick={() => setSelectedTab('incoming')}
            className={`tab h-fit py-2 text-xl ${selectedTab === 'incoming' ? 'tab-active' : ''}`}
          >
            {t('parties.incoming_parties')}
          </span>
          <span
            onClick={() => setSelectedTab('past')}
            className={`tab h-fit py-2 text-xl ${selectedTab === 'past' ? 'tab-active' : ''}`}
          >
            {t('parties.past_parties')}
          </span>
        </div>
      </div>
      <PartyTable filterable data={parties[selectedTab]} title={t(`parties.${selectedTab}_parties`)} />
    </div>
  );
};

type FormOptions = { places: ReadonlyArray<option>; themes: ReadonlyArray<option>; djs: ReadonlyArray<option> };

const CreateParty = () => {
  const { t } = useTranslation();
  const pushToast = usePushToast();
  const [isOpen, setOpen] = useState(false);
  const [djMode, setDjMode] = useState<'add' | 'select'>('select');
  const [formOptions, setFormOptions] = useState<FormOptions>({ places: [], themes: [], djs: [] });
  const [selectedThemes, setSelectedThemes] = useState<ReadonlyArray<option>>([]);

  useEffect(() => {
    Promise.all([new Place().getMany(), new Theme().getMany(), new Dj().getMany()]).then(
      ([{ items: placesItems }, { items: themesItems }, { items: djsItems }]) => {
        setFormOptions({
          places: placesItems.map(({ name, '@id': id }) => ({ name, value: `${id}` })),
          themes: themesItems.map(theme => ({ name: theme.name, value: theme['@id'] ?? '' })),
          djs: djsItems.map(({ username, '@id': id }) => ({ name: username, value: `${id}` })),
        });
      },
    );
  }, [setFormOptions]);

  return (
    <>
      <BaseButton
        className="w-fit text-white"
        variant="primary"
        icon="plus-square"
        text={t('party.create.title')}
        onClick={() => {
          setOpen(true);
        }}
      />
      {isOpen && (
        <SimpleModal isOpen onClose={() => setOpen(false)}>
          <Form
            title={t('party.create.title')}
            titleSize="h3"
            validate={values => {
              if (!values.themes.trim().length) {
                return [t('party.create.form.validation.themes.error')];
              }
              return [];
            }}
            inputs={[
              {
                placeholder: t('party.create.form.place.placeholder'),
                defaultValue: '',
                name: 'place',
                label: t('party.create.form.place.label'),
                type: 'select',
                required: true,
                options: formOptions.places,
              },
              {
                name: 'date',
                label: t('party.create.form.date.label'),
                type: 'date',
              },
              {
                placeholder: t('party.create.form.themes.placeholder'),
                name: 'themes',
                isMultiple: true,
                label: 'Themes',
                type: 'select',
                options: formOptions.themes,
                selectedOptions: selectedThemes,
                handleChange: setSelectedThemes,
                required: true,
              },
              ...(djMode === 'add'
                ? [
                    {
                      name: 'pseudo',
                      label: t('party.create.form.pseudo.label'),
                      placeholder: t('party.create.form.pseudo.placeholder'),
                    },
                    {
                      name: 'email',
                      label: t('party.create.form.email.label'),
                      placeholder: t('party.create.form.email.placeholder'),
                      type: 'email',
                    },
                  ]
                : [
                    {
                      placeholder: t('party.create.form.dj.placeholder'),
                      defaultValue: '',
                      name: 'dj',
                      label: t('party.create.form.dj.label'),
                      type: 'select',
                      options: formOptions.djs,
                      required: true,
                    } as Selectable,
                  ]),
            ]}
            buttonProps={{
              text: t('common.form.create'),
              variant: 'success',
            }}
            handleSubmit={async values => {
              let djId;
              if (djMode === 'add') {
                djId = await new User()
                  .create({ username: values.pseudo, email: values.email } as UserModel)
                  .then(({ id }) => {
                    return id;
                  })
                  .catch(err => {
                    throw err;
                  });
              }
              return new Party()
                .create({
                  ...values,
                  place: { id: values.place },
                  themes: selectedThemes.map(theme => theme.value),
                  dj: { id: djId ?? values.dj },
                } as PartyModel)
                .then(() => {
                  setOpen(false);
                  pushToast({ text: t('party.create.success'), variant: 'success' });
                })
                .catch(err => {
                  pushToast({ text: t('party.create.fail'), variant: 'danger' });
                  throw err;
                });
            }}
          >
            <div>
              <BaseButton
                className="btn-sm"
                variant="success"
                icon={djMode === 'add' ? 'users' : 'user-plus'}
                position="right"
                text={t(`party.create.dj_mode.${djMode === 'add' ? 'select' : 'add'}`)}
                onClick={e => {
                  e.preventDefault();
                  setTimeout(() => {
                    setDjMode(prev => (prev === 'add' ? 'select' : 'add'));
                  }, 100);
                }}
              />
            </div>
          </Form>
        </SimpleModal>
      )}
    </>
  );
};

const Parties = () => {
  useRedirectIfNotLogged();
  const { t } = useTranslation();
  const [parties, setParties] = useState<reducerType>({
    incoming: [],
    past: [],
    today: [],
  });

  useEffect(() => {
    new Party().getMany().then(({ items }) => {
      const now = new Date();
      const parties = items.reduce(
        (acc: reducerType, party) => {
          if (party.date.toDateString() === now.toDateString()) {
            acc['today'] = [...acc['today'], party];
            return acc;
          }
          if (party.date < now) {
            acc['past'] = [...acc['past'], party];
          } else {
            acc['incoming'] = [...acc['incoming'], party];
          }
          return acc;
        },
        { past: [], incoming: [], today: [] },
      );

      parties.incoming = parties.incoming.toSorted(sortDate);
      parties.past = parties.past.toSorted(sortDate);
      parties.today = parties.today.toSorted(sortDate);

      setParties(parties);
    });
  }, [setParties, sortDate]);

  return (
    <div className="w-full lg:max-w-4xl lg:max-w-6xl mx-auto flex flex-col gap-16 py-8 lg:py-24 px-4 lg:px-16">
      <div>
        <CreateParty />
        <PartyTable data={parties.today} title={t('parties.today')} />
      </div>
      <TabbedParties parties={parties} />
    </div>
  );
};

export default Parties;
